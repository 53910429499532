import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { getFirebaseBackend } from '../../authUtils';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;
    private currentUserSubject: BehaviorSubject<User>;

    constructor(private http:HttpClient,private router:Router) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */

    login(email: string, password: string) {
      return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
     });

    }

    loginNubeDb(datosConsultaApi:string){
       return this.http.post(`${environment.BackEndApi}Seguridad/Ingreso/Usuarios`,datosConsultaApi);
    }
    validarTokent(){
      if(sessionStorage.getItem(environment.tokenauth)){
        let options = this.tokenautopeticion();
        return this.http.get(`${environment.BackEndApi}Seguridad/tokentValido`,options);
      }else{
        return false;
      }

    }
    validarTokentSessionPrevia(tokent:any){
        return this.http.get(`${environment.BackEndApi}Seguridad/tokentValido/${tokent}`);
    }
    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        getFirebaseBackend().logout();
           /**valido cerrar sesion */
        // remove user from local storage to log user out
        localStorage.removeItem(environment.tokenauth);
        localStorage.removeItem(environment.PlanauthEmpresa);
        //localStorage.removeItem(environment.PlanauthEmpresa);
        sessionStorage.clear();
        this.currentUserSubject.next(null);
    }
    tokenautopeticion(){
      if(sessionStorage.getItem(environment.tokenauth)){
      let tok = "Bearer "+sessionStorage.getItem(environment.tokenauth);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': tok.replace(/['"]+/g,'')
       });
      let options = { headers: headers };
       return options;
      }else{
        let headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'authorization': '0'
         });
        let options = { headers: headers };
        return options;
      }
    }
}

