import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ConsultasGetService {

  constructor(private http: HttpClient, private router: Router) { }

  consultaDatosAdmin() {
    if (sessionStorage.getItem(environment.tokenauth)) {
      let options = this.tokenautopeticion();
      return this.http.get(`${environment.BackEndApi}Seguridad/Datos/Admin/`, options);
    } else {

    }
  }
  ConsultarMigracionIpv6() {
    if (sessionStorage.getItem(environment.tokenauth)) {
      let options = this.tokenautopeticion();
      return this.http.get(`${environment.BackEndApi}olt/Consultar/Migracion/IPV6`, options);
    } else {

    }
  }
  calcularTiempoEnSoporte(enCamino?: string, finalizado?: string): string {

    function calcularTiempoDesde(inicio: Date, fin: Date): string {
      const diferenciaEnMinutos = Math.floor((fin.getTime() - inicio.getTime()) / (1000 * 60));

      if (diferenciaEnMinutos <= 0) {
        return 'Hace un momento';
      }

      if (diferenciaEnMinutos === 1) {
        return 'Hace 1 minuto';
      }

      if (diferenciaEnMinutos < 60) {
        return `Hace ${diferenciaEnMinutos} minutos`;
      }

      const horas = Math.floor(diferenciaEnMinutos / 60);
      const minutos = diferenciaEnMinutos % 60;

      if (horas === 1) {
        return minutos > 0 ? `Hace 1 hora y ${minutos} minutos` : 'Hace 1 hora';
      }

      if (horas < 24) {
        return `Hace ${horas} horas y ${minutos} minutos`;
      }

      const dias = Math.floor(horas / 24);
      if (dias === 1) {
        return 'Hace 1 día';
      }

      if (dias < 30) {
        return `Hace ${dias} días`;
      }

      const meses = Math.floor(dias / 30);
      if (meses === 1) {
        return 'Hace 1 mes';
      }

      if (meses < 12) {
        return `Hace ${meses} meses`;
      }

      const años = Math.floor(meses / 12);
      return `Hace ${años} años`;
    }

    function calcularDiferencia(inicio: Date, fin: Date) {
      const diferenciaEnMinutos = Math.floor((fin.getTime() - inicio.getTime()) / (1000 * 60));
      const horas = Math.floor(diferenciaEnMinutos / 60);
      const minutos = diferenciaEnMinutos % 60;

      return `${horas} horas y ${minutos} minutos`;
    }
    if (!enCamino) {
      return 'No hay fecha de inicio disponible';
    }

    let mensaje = '';
    const fechaInicio = new Date(enCamino);
    let fechaFin: Date;

    if (finalizado) {
      // Ambas fechas están disponibles
      fechaFin = new Date(finalizado);
      mensaje = `Tiempo en solución: ${calcularDiferencia(fechaInicio, fechaFin)}`;
    } else {
      // Solo la fecha "en camino" está disponible
      fechaFin = new Date();
      mensaje = calcularTiempoDesde(fechaInicio, fechaFin);
    }

    return mensaje;
  }


  /**WIDGETS  */
  WidgetBalanceOperador() {
    return this.http.get(`${environment.BackEndApi}Usuario/widget`, this.tokenautopeticion());
  }

  WidgetClientes() {
    return this.http.get(`${environment.BackEndApi}Empresa/Widget/Clientes`, this.tokenautopeticion());
  }

  WidgetContratos() {
    return this.http.get(`${environment.BackEndApi}Empresa/Widget/Contratos`, this.tokenautopeticion());
  }

  WidgetFacturacion() {
    return this.http.get(`${environment.BackEndApi}Empresa/Widget/Facturacion`, this.tokenautopeticion());
  }

  WidgetHistoricoFacturacion() {
    return this.http.get(`${environment.BackEndApi}Empresa/Widget/HistorialFacturacion`, this.tokenautopeticion());
  }

  WidgetSoportesFacturacion() {
    return this.http.get(`${environment.BackEndApi}Empresa/Widget/HistorialSoportes`, this.tokenautopeticion());
  }

  WidgetCountWsp() {
    return this.http.get(`${environment.BackEndApi}Empresa/Widget/HistorialMensajeria`, this.tokenautopeticion());
  }

  WidgetBalanceGeneral(id: number) {
    return this.http.get(`${environment.BackEndApi}Empresa/Widget/BalanceGeneral/${id}`, this.tokenautopeticion());
  }

  WidgetCrecimineto(id_sucursal: number, id_mes: number) {
    return this.http.get(`${environment.BackEndApi}Empresa/Widget/Crecimiento/${id_sucursal}/${id_mes}`, this.tokenautopeticion());
  }
  WidgetIncidencias(id_sucursal: number, id_mes: number,filtros) {
    return this.http.post(`${environment.BackEndApi}Empresa/Widget/Soportes/${id_sucursal}/${id_mes}`,filtros, this.tokenautopeticion());
  }
  ReporteDocumentos(id: number) {
    return this.http.get(`${environment.BackEndApi}Empresa/Widget/BalanceGeneral/reporte/${id}`, {
      ...this.tokenautopeticion(),
      responseType: 'blob'
    });
  }

  tokenautopeticion() {
    if (sessionStorage.getItem(environment.tokenauth)) {
      let tok = "Bearer " + sessionStorage.getItem(environment.tokenauth);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': tok.replace(/['"]+/g, '')
      });
      let options = { headers: headers };
      return options;
    } else {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': '0'
      });
      let options = { headers: headers };
      return options;
    }
  }
}
