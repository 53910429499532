// export layout related constants
export const LAYOUT_VERTICAL = 'vertical';

export const LAYOUT_HORIZONTAL = 'horizontal';

export const LAYOUT_WIDTH = 'fluid';

export const SIDEBAR_TYPE = 'dark';

export const TOPBAR = 'dark';

export const LAYOUT_MODE = 'light';
export class MdatosAdmin {
  constructor(public n_identificacion: string,
    public nombres: string,
    public apellidos: string,
    public email: string,
    public id_rol: number,
    public img_profile: string,
    public nombre_comercial: string,
    public detalle: string) { }
}