<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © MasterCode Latam <i class="mdi mdi-alien-outline
"></i>.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Design & Develop by MasterCode Latam <i class="mdi mdi-alien-outline
"></i>
                </div>
            </div>
        </div>
    </div>
</footer>