import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './extrapages/page404/page404.component';


const routes: Routes = [
  { path:'auth', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  {
    path: '',
    component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard],
  },
  { 
    path: 'error',
    component: Page404Component,
    data: {
      'type': 404,
      'title': 'Pagina No Econtrada',
      'desc': 'Sin Acceso.'
    }
  },
  {
    path: 'error/:type',
    component: Page404Component
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
