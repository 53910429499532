import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import jwtDecode from 'jwt-decode';

import { TokentDetails } from 'src/app/pages/Building/models/empresa.models';
import { environment } from 'src/environments/environment.prod';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const token = sessionStorage.getItem(environment.tokenauth);
      if (token) {
        const decodedToken: TokentDetails = jwtDecode(token);
  
        if (decodedToken && decodedToken.exp) {
          const expirationDate = new Date(decodedToken.exp * 1000);
          const currentDate = new Date();
  
          if (currentDate < expirationDate) {
            // El token no ha caducado, puedes continuar
            return true;
          } else {
            sessionStorage.removeItem(environment.tokenauth);
            // El token ha caducado, redirecciona al usuario a la página de inicio de sesión
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
          }
        }
      }
  
      // No se encontró un token en sessionStorage o no tiene una fecha de expiración válida
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
}
